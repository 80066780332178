import React from 'react';

import Layout from '../components/layout';
import { navSections } from '../components/navbar';
import { DLinksLandingPage } from '../components/DLinksLandingPage';

const AboutDLinksPage = () => {
  const navbarRef = React.useRef(null);

  return (
    <Layout noFooter navbarRef={navbarRef} currentSection={navSections.PRODUCTS}>
      <DLinksLandingPage navbarRef={navbarRef} />
    </Layout>
  );
};

export default AboutDLinksPage;
