import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import {
  Video,
  Information,
  FancyTitle,
  FlexSwitcher,
  KeyPoints,
  KeyPointsItem,
  Marker,
  CtaButton,
  CtaButtonGradientBackground,
  FomoExample,
} from './DLinksLandingPage.StyledComponents';
import { Footer } from '../footer';
import { events } from '../../utils/trackEvent';
import { usePageMetaAttributes } from '../../utils/usePageMetaAttributes';
import config from '../../config';
import SEO from '../../components/seo';

// Same as what is used in main app
const STORAGE_KEY = 'namebase:user:dlinks:hasSeenLandingPage';

const DLinksLandingPageQuery = graphql`
  query DLinksLandingPageQuery {
    content: contentfulDLinksLandingPage {
      ...DLinksLandingPageContent
    }
  }
`;

const DLinksLandingPage = ({ navbarRef }) => {
  const meta = usePageMetaAttributes('dlinks');
  const { content } = useStaticQuery(DLinksLandingPageQuery);

  const [videoHeightOffset, setVideoHeightOffset] = React.useState(0);
  const [networkEffectiveType, setNetworkEffectiveType] = React.useState('4g');

  // Small performance boost for users on slow connections: do not
  // autoplay the video if the users network is not at least as good as 4g.
  // Since this isn't supported for all browsers, we'll default to autoplay for
  // now and set it to false if otherwise advised by the below.
  React.useEffect(() => {
    if (
      !window ||
      !window.navigator ||
      !window.navigator.connection ||
      !window.navigator.connection.effectiveType ||
      typeof window.navigator.connection.effectiveType !== 'string'
    ) {
      return;
    }

    setNetworkEffectiveType(window.navigator.connection.effectiveType);
  }, []);

  React.useLayoutEffect(() => {
    const updateVideoHeightOffset = () => {
      setVideoHeightOffset(navbarRef.current.clientHeight);
    };

    updateVideoHeightOffset();
    window.addEventListener('resize', updateVideoHeightOffset);

    return () => {
      window.removeEventListener('resize', updateVideoHeightOffset);
    };

    // I only want this to happen once!!
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCtaClick = () => {
    events.dLinks.succumbToCta.send();

    // These storage keys tell the main app if/when they should
    // send the user to view this welcome page
    localStorage.setItem(STORAGE_KEY, true);
    sessionStorage.setItem(STORAGE_KEY, true);

    window.location = `${config.rootUrl}/dlinks/app`;
  };

  return (
    <React.Fragment>
      <SEO {...meta} />
      <div style={{ position: 'sticky', top: 0 }}>
        <Video
          controls
          autoPlay={networkEffectiveType === '4g'}
          muted
          playsInline
          heightOffset={videoHeightOffset}
          src={content.video.file.url}
          poster={content.videoPoster.file.url}>
          Looks like your browser doesn't support embedded videos.
        </Video>
      </div>
      <div style={{ position: 'sticky', top: 0, zIndex: 1 }}>
        <Information>
          <FancyTitle>{content.overline}</FancyTitle>
          <FlexSwitcher>
            <div>
              <KeyPoints>
                <div>
                  <Marker />
                </div>
                <KeyPointsItem>{content.keyPoint1}</KeyPointsItem>
                <div>
                  <Marker />
                </div>
                <KeyPointsItem>{content.keyPoint2}</KeyPointsItem>
                <div>
                  <Marker />
                </div>
                <KeyPointsItem>{content.keyPoint3}</KeyPointsItem>
              </KeyPoints>
              <CtaButtonGradientBackground>
                <CtaButton onClick={onCtaClick}>
                  <span>Get started</span>
                </CtaButton>
              </CtaButtonGradientBackground>
            </div>
            <div>
              {content.fomoExamples.map(example => (
                <FomoExample
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://${example.fullDomainName}.hns.to`}>
                  <div>
                    <img src={example.imageSrc} />
                    <p>{`See ${example.name}'s`}</p>
                  </div>
                  <div>
                    <RightArrow />
                  </div>
                </FomoExample>
              ))}
            </div>
          </FlexSwitcher>
        </Information>
        <Footer />
      </div>
    </React.Fragment>
  );
};

const RightArrow = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 16L18 12L14 8" stroke="#091F43" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M17.136 12.0283L5.75115 12.0283"
      stroke="#091F43"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { DLinksLandingPage };
