import styled, { css } from 'styled-components';

export const Video = styled.video`
  object-fit: cover;
  width: 100%;
  height: calc(100vh - ${({ heightOffset }) => heightOffset}px);

  @media ${({ theme }) => theme.breakpoints.sm} {
    height: auto
  }
`;

export const Information = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  padding: ${({ theme }) => theme.spacing(14)};
  padding-bottom: ${({ theme }) => theme.spacing(18)};
  padding-top: ${({ theme }) => theme.spacing(8.5)};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding: ${({ theme }) => theme.spacing(2)};
    padding-bottom: ${({ theme }) => theme.spacing(13)};
    padding-top: ${({ theme }) => theme.spacing(6)};
  }
`;

export const FancyTitle = styled.h1`
  font-size: 46px;
  line-height: 56px;
  max-width: 1120px;
  text-align: center;
  font-family: 'Roboto Variable', Roboto;
  font-variation-settings: 'wght' 300;
  background: -webkit-linear-gradient(90deg, #FF5980 11.16%, #FF5980 11.56%, #FFA882 88.22%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media ${({ theme }) => theme.breakpoints.sm} {
    font-size: 28px;
    line-height: 36px;
  }
`;

export const FlexSwitcher = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.spacing(10)};

  @media ${({ theme }) => theme.breakpoints.sm} {
    min-width: 100%;
    flex-direction: column;
    margin-top: ${({ theme }) => theme.spacing(5)};
  }
`

export const KeyPoints = styled.div`
  display: grid;
  column-gap: ${({ theme }) => theme.spacing(3)};
  row-gap: ${({ theme }) => theme.spacing(2)};
  grid-template-columns: min-content auto;
  grid-template-areas:
    'marker1 item1'
    'marker2 item2'
    'marker3 item3';
  padding-right: ${({ theme }) => theme.spacing(20)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  min-width: max-content;

  @media ${({ theme }) => theme.breakpoints.md} {
    padding-right: ${({ theme }) => theme.spacing(13.5)};
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding-right: 0px;
  }
`;

export const KeyPointsItem = styled.div`
  color: ${({ theme }) => theme.color.brandDark};
  font-size: ${({ theme }) => theme.text.small.size};
  line-height: ${({ theme }) => theme.text.small.lineHeight};
`;

export const Marker = styled.hr`
  border: 0;
  margin-top: 1.33rem;
  width: ${({ theme }) => theme.spacing(4)};
  height: 1px;
  background: linear-gradient(177.14deg, #ff5980 1.46%, #ffa882 98.22%);
  transform: scaleY(1.05); /* Fix for Chrome display issue when zoomed out */
`;

export const CtaButtonGradientBackground = styled.div`
  background: linear-gradient(140deg, #ff5980 1.46%, #ffa882 98.22%);
  width: 122.25px;
  height: 42.25px;
  border-radius: ${({ theme }) => theme.borderRadii.standard};
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scaleY(1.05); /* Fix for Chrome display issue when zoomed out */

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 100%;
    height: 50px;
    margin-bottom: ${({ theme }) => theme.spacing(5)};

    div {
      width: 100%;
      margin-left: 1px;
      margin-right: 1px;
    }
  }
`;

export const CtaButton = styled.button`
  width: 120px;
  height: 40px;
  background: ${({ theme }) => theme.color.white};
  border: none;
  border-radius: 3px;

  span {
    background: -webkit-linear-gradient(275deg, #FF5980 1.46%, #FFA882 98.22%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: ${({ theme }) => theme.text.small.size};
    line-height: ${({ theme }) => theme.text.small.lineHeight};
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 100%;
    height: 48px;
  }

  &:hover {
    background: inherit;
    span {
      background: none;
      -webkit-text-fill-color: ${({ theme }) => theme.color.white};
    }
  }
`;

export const FomoExample = styled.a`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ theme }) => theme.spacing(30)};

  * {
    transition: transform 0.2s ease-in-out;
  }

  div {
    display: flex;
    align-items: center;
  }

  p {
    font-family: 'Roboto Variable';
    font-size: ${({ theme }) => theme.text.tiny.size};
    line-height: ${({ theme }) => theme.text.tiny.lineHeight};
    font-variation-settings: 'wght' 500;
  }

  img {
    width: ${({ theme }) => theme.spacing(7)};
    height: ${({ theme }) => theme.spacing(7)};
    border-radius: 50%;
    margin-right: ${({ theme }) => theme.spacing(2)};
  }

  &:hover {
    * {
      color: ${({ theme }) => theme.color.nbPrimary};
      stroke: ${({ theme }) => theme.color.nbPrimary};
    }

    svg {
      transform: translateX(4px);
    }
  }
`;